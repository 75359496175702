import React, { useEffect, useState } from "react";
import TutorialVideo from "../components/Tutorials/TutorialVideo";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTutorial } from "../store/allTutorilas";
const SingleTutorial = () => {
  const [id, setId] = useState(null);
  const [title, setTitle] = useState(null);
  const param = useParams();
  const data = useSelector((state) => state.tutorials);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTutorial());
  }, [dispatch]);

  useEffect(() => {
    setId(param.id);
  }, [param.id]);

  useEffect(() => {
    if (id && data?.allTutorials) {
      const tutorial = data?.allTutorials?.tbl?.[0]?.r.find((el) => el.id === id);
      setTitle(tutorial);
    }
  }, [id, data]);
  return (
    <>
      {title && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "90vh",
            width: "100%",
          }}
        >
          {/* <h1>React Youtube Embedding Example</h1> */}
          <TutorialVideo title={title}/>
          <Typography sx={{ width: "80%", textAlign: "left", marginTop: "2%",fontSize:"25px",fontWeight:"bold" }}>
            {title?.videoTitle}
          </Typography>
          <Typography sx={{ width: "80%", textAlign: "left", marginTop: "10px",fontWeight:"600",fontSize:"18px" }}>
            Category: {title?.videoCategory}
          </Typography>
          <Typography sx={{ width: "80%", textAlign: "left", marginTop: "10px",color:"gray" }}>
            Duration: {title?.videoDuration}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SingleTutorial;
