import { styled } from "@material-ui/core";
import { Box, Card, Typography } from "@mui/material";

export const MyCard = styled(Card)({
    // boxShadow:"rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
    padding: "0",
    width: "16%",
    borderRadius: "25px !important",
    boxShadow: "5px 5px 10px gray !important",
    "@media (max-width:865px)": {
        width: "75%",
        margin:"10px 0px"
    },
    "@media (max-width:1265px)": {
        width: "100%",
        margin:"10px 0px"
    },
    width:"24%",
    marginTop:"2%",
    minHeight:"55vh"

});
export const Cont = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "row wrap",
    width: "78%",
    // height:"70vh",
    margin:"0 auto",
    gap: "3%",
    "@media (max-width:765px)": {
        marginTop:"0%",
        flexDirection: "column",
    },
})
export const customStyles = {
    fontSize: "14px",
};
export const MyPrice = styled(Typography)({
    fontSize: "25px !important",
    // color: "#333333",
    fontWeight: "600 !important",
    fontFamily: "Open Sans",
    width: "100%",
    textAlign: "center",
    color: "white",
    borderRadius: "30px",
    padding: "5px 0px"
    // color: "green",
});
export const MyTypography = styled(Typography)({
    letterSpacing: "0.00938em",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: "700",
    fontSize: "18px",
    fontFamily: "Open Sans",
    minHeight: "84px",
});
export const MyTypography2 = styled(Typography)({
    fontSize: "12px",
    position: "relative",
    top: "10px",
});