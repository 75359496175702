import { createSlice } from '@reduxjs/toolkit'

const initialState = {
serviceList:[],
serviceName:[]
}

const allServiceList = createSlice({
  name: "serviceList",
  initialState,
  reducers: {
    getServiceList:(state, action)=>{
        state.serviceList = action.payload
      },
    getServiceName:(state, action)=>{
        state.serviceName = action.payload
      },
  }
});

export const {getServiceList,getServiceName} = allServiceList.actions

export default allServiceList.reducer