import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { callApi } from "../../Api/callApi";
import { getTrainingsList } from "../../store/allTraining";

export default function GetTrainingsList(startLimit, endLimit) {
  const dispatch = useDispatch();

  useEffect(() => {
    callApi("DpsGetTrainingList", {
      startLimit: startLimit,
      endLimit: endLimit,
    }).then((res) => dispatch(getTrainingsList(res?.tbl[0]?.r)));
  }, [dispatch]);
}
