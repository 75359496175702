import React from "react";
import Typography from "@mui/material/Typography";
import Alltutorials from "../components/Tutorials/Alltutorials";
import { tutorials } from "../components/Tutorials/data";
const Tutorial = () => {
  return (
    <>
      <Typography
        sx={{
          fontSize: "30px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Tutorials
      </Typography>
      {tutorials && tutorials.map((el,i)=>{
        return(<Alltutorials bool={false} data={el} key={i} />)
      })}
    </>
  );
};

export default Tutorial;
