import { styled } from "@material-ui/core";
import { Typography,Box } from "@mui/material";
export const Typography1 = styled(Typography)({
    fontSize: "14px",
    color: "black",
    display: "flex",
    alignItems: "center",
    minWidth: "100px",
    fontWeight: "700",
    marginRight: "10px",
    fontFamily: "Open Sans",
});
export const Typography3 = styled(Typography)({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontSize: "14px",
});

export const PriceBox = styled(Box)({
    display: "flex",
    alignItems: "center",
});
export const BoxLetter = styled(Box)({
    borderRadius: "10px",
});

export const Box4 = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    background: "#e4e4e4",
    flexDirection: "column",
    padding: "10px",
});
export const Box5 = styled(Box)({
    marginBottom: "10px",
    position: "relative",
});