import { Box, Grid } from "@mui/material";
import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { NameTypo,AboutTypo,PositionTypo,myImage, Cont } from "./style/instructor";
function Insturctor({ trainer }) {
  
  const RawHtml = ({ children = "", tag: Tag = "div", ...props }) => (
    <Tag {...props} dangerouslySetInnerHTML={{ __html: children }} />
  );
  return (
    <Cont >
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: "#f5f5f5",
          padding: "15px",
          borderRadius: "10px",
        }}
      >
        <Grid item xs={12} sm={3}>
          <Box>
            <img style={myImage} src={trainer.traineeImage} alt="" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <NameTypo sx={{margin:"0px"}}>{trainer.trainerFullname}</NameTypo>
              <a href={trainer.trainerLinkedin} target="_blank" rel="noreferrer">
                <LinkedInIcon color="primary" />
              </a>
            </Box>
            <PositionTypo sx={{marginTop:"4px"}}>
              {trainer.trainerJobPosition} at {trainer.trainerJobCompany}
            </PositionTypo>
            <AboutTypo>
              <Box sx={{  marginTop: "15px" }}>
                <RawHtml tag={"span"} style={{ fontSize: "14px" }}>
                  {trainer.trainerBiography}
                </RawHtml>
              </Box>
            </AboutTypo>
          </Box>
        </Grid>
      </Grid>
    </Cont>
  );
}

export default Insturctor;
