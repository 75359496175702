import axios from "axios";
export async function getRelatedProd(startLimit, endLimit, value) {
    try {
        const response = await axios.post(`https://apimekan.com/sourced_agile/api/post/calle/GetDigitalProductSummarySampleList`, {
            "kv": {
                startLimit: startLimit,
                endLimit: endLimit,
                "id": value
            }
        }

        );

        const data = response.data;
        return data;
    } catch (error) {
        return error;
    }
}