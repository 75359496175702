import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Skeleton, Typography } from "@mui/material";
// import CheckIcon from "@mui/icons-material/Check";
import Insturctor from "../components/Training/Instructor/Insturctor";
import { useParams } from "react-router-dom";
import { getOneElem } from "../utils/crud/getOneElem";
import MentorCard from "../components/Mentor_part/MentorCard";
import ProductContact from "../components/Product_Owner/ProductContact/ProductContact";
import {
  Container,
  DescTitles,
  DiscountPrice,
  Join,
  JoinText,
  MainTitles,
  MyBox2,
  MyBox3,
  MyTypography,
  Price,
  SectionTitle,
  TimeTypo,
  TimeTypo2,
} from "../components/Training/styles/training";
import { getRelatedProd } from "../utils/crud/getRelatedProd";
function InfoTraining() {
  const { id } = useParams();
  const [training, setTraining] = useState(null);
  const [register, setRegister] = useState(false);
  const [allCourse, setAllCourse] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const data = await getOneElem({
        kv: {
          id: id,
        },
      });
      setTraining(data.tbl[0].r[0]);
    }
    fetchData();
  }, [id, setTraining]);
  console.log(training);
  useEffect(() => {
    async function getRelated() {
      if (training && training.relatedDpSamples) {
        const data = await getRelatedProd(0, 3, training.relatedDpSamples);
        setAllCourse(data.tbl[0].r);
      }
    }
    getRelated();
  }, [training, setAllCourse]);
  // console.log(training);
  const RawHtml = ({ children = "", tag: Tag = "div", ...props }) => (
    <Tag {...props} dangerouslySetInnerHTML={{ __html: children }} />
  );
  return (
    <Container>
      {training !== null  ? (
        <Box>
          {/* About the Card Start */}
          <Box>
            <Box sx={{ marginBottom: "60px" }}>
              <MainTitles>{training.trainingTitle}</MainTitles>
              <DescTitles>{training?.categoryName}</DescTitles>
            </Box>
            {/* <Part1> start */}
            <Grid container>
              <Grid item xs={12} sm={5} style={{ marginBottom: "20px" }}>
                <Box>
                  <img
                    src={training.fileUrl}
                    alt=""
                    style={{ width: "50%", marginBottom: "30px" }}
                  />
                </Box>
                <RawHtml tag={"span"} style={{ fontSize: "14px" }}>
                  {training.description}
                </RawHtml>
              </Grid>
              <Grid item xs={0} sm={1}></Grid>

              <Grid item xs={12} sm={6}>
                <MyBox2>
                  <Box sx={{ marginBottom: "15px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6} sm={6}>
                        <Price>
                          <TimeTypo2>Price</TimeTypo2>
                          <TimeTypo sx={{ display: "inline" }}>
                            {training.finalPrice}
                          </TimeTypo>
                          <DiscountPrice>
                            {training.discountPrice}
                          </DiscountPrice>
                        </Price>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={6}>
                        <Join
                          onClick={() => {
                            if (training.isSoldOut === "0") {
                              setRegister(!register);
                            }
                          }}
                        >
                          <JoinText>
                            {training.isSoldOut === "0" ? (
                              <>JOIN</>
                            ) : (
                              <>SOLD OUT</>
                            )}
                          </JoinText>
                          <TimeTypo></TimeTypo>
                        </Join>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ marginBottom: "15px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6} sm={6}>
                        <MyBox3>
                          <TimeTypo2>Period</TimeTypo2>
                          <TimeTypo>{training.startDate}</TimeTypo>
                        </MyBox3>
                      </Grid>
                      <Grid item xs={12} lg={6} sm={6}>
                        <MyBox3>
                          <TimeTypo2>
                            DAY (Time zone: {training.timeZone})
                          </TimeTypo2>
                          <TimeTypo>
                            <RawHtml tag={"span"} style={{ fontSize: "14px" }}>
                              {training.materialAccessDuration}
                            </RawHtml>
                          </TimeTypo>
                        </MyBox3>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ marginBottom: "15px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6} sm={6}>
                        <MyBox3>
                          <TimeTypo2>PRACTICE HOURS</TimeTypo2>
                          <TimeTypo>{training.practiceHours}</TimeTypo>
                        </MyBox3>
                      </Grid>
                      <Grid item xs={12} lg={6} sm={6}>
                        <MyBox3>
                          <TimeTypo2>SELF PRACTICE HOURS</TimeTypo2>
                          <TimeTypo>{training.selfPracticeHours}</TimeTypo>
                        </MyBox3>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ marginBottom: "15px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6} sm={6}>
                        <MyBox3>
                          <TimeTypo2>TRAINING LANGUAGE</TimeTypo2>
                          <TimeTypo>{training.trainingLang}</TimeTypo>
                        </MyBox3>
                      </Grid>
                      <Grid item xs={12} lg={6} sm={6}>
                        <MyBox3>
                          <TimeTypo2>MATERIAL LANGUAGE</TimeTypo2>
                          <TimeTypo>{training.materialLang}</TimeTypo>
                        </MyBox3>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6} sm={6}>
                        <MyBox3>
                          <TimeTypo2>TECHING FORMAT</TimeTypo2>
                          <TimeTypo>{training.format}</TimeTypo>
                        </MyBox3>
                      </Grid>
                    </Grid>
                  </Box>
                </MyBox2>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <SectionTitle>You Will Learn</SectionTitle>
            <RawHtml tag={"span"} style={{ fontSize: "14px" }}>
              {training.youWillLearn}
            </RawHtml>
          </Box>
          <Box sx={{ marginTop: "40px" }}>
            <SectionTitle>Live Online Teaching Process</SectionTitle>
            <RawHtml tag={"span"} style={{ fontSize: "14px" }}>
              {training.teachingProcess}
            </RawHtml>
          </Box>
          <SectionTitle sx={{ textAlign: "left", margin: "40px" }}>
            Instructor
          </SectionTitle>

          <Insturctor trainer={training} />
          <Box sx={{ marginTop: "90px" }}>
            <MyTypography sx={{ textAlign: "left" }}>
              Related Samples
            </MyTypography>
            <Box sx={{ maxWidth: "900px" }}>
              <Grid
                container
                spacing={5}
                sx={{ padding: { xs: "0px 0px", sm: "auto" } }}
              >
                {allCourse &&
                  allCourse.map((index, key) => (
                    <Grid
                      key={key}
                      sx={{ minHeight: "360px" }}
                      item
                      xs={12}
                      sm={4}
                    >
                      <MentorCard index={index} />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Box>
          <ProductContact
            setOpenCntc={setRegister}
            openCntc={register}
            detail={training.trainingTitle}
            id={id}
            desc={training.categoryName}
          />
        </Box>
      ) : (
        <Box sx={{width:"100%",height:"100vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
          <CircularProgress style={{ width: 150, height: 150 }} />
        </Box>
      )}
      
    </Container>
  );
}

export default InfoTraining;
