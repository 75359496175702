import { Box, Skeleton, styled } from "@mui/material";
import AllProduct from "../components/Product_Owner/AllProduct";
import { useEffect, useState } from "react";
import { addMapDesc } from "../utils/crud/addRoadMapDesc";
import { Container, LessBtn } from "../components/Product_Owner/style/product";
function Product() {
  const [isOpen, setIsOpen] = useState(false);
  const [mapDesc, setMapDesc] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const data = await addMapDesc();
      setMapDesc(data.tbl[0].r[0]);
    }

    fetchData();
  }, []);
  function toggleAccordion() {
    setIsOpen(!isOpen);
  }

  const MyTypography = styled(Box)({
    color: "#333333",
    fontSize: 28,
    fontWeight: 700,
    fontFamily: "Open Sans",
    textAlign: "center",
    margin: "50px 0px",

    "@media (maxWidth:665px)": {
      fontSize: "20px",
    },
  });
  let allData = true;
  //   Box2-Image Part Start
  const RawHtml = ({ children = "", tag: Tag = "div", ...props }) => (
    <Tag {...props} dangerouslySetInnerHTML={{ __html: children }} />
  );
  return (
    <Container>
      <MyTypography>
      We help companies to build a Profesional Digital Product Owner team
      </MyTypography>
      <MyTypography sx={{fontSize:"23px",color:"gray"}}>
        Topics required for Digital Product Analysis and Management
      </MyTypography>

      {mapDesc !== null ? (
        <div className="accordion">
          <div
            onClick={toggleAccordion}
            className={`accordion-content ${isOpen ? "open" : "closed"}`}
            style={{ maxHeight: isOpen ? "1400px" : "220px" }}
          >
            <p>
              <RawHtml tag={"span"} style={{ fontSize: "14px" }}>
                {mapDesc && mapDesc.description}
              </RawHtml>
            </p>
            {isOpen ? (
              <LessBtn onClick={() => setIsOpen(false)}>show less</LessBtn>
            ) : (
              <p className="more-link"></p>
            )}
          </div>
        </div>
      ) : (
        <Box>
          <Skeleton animation="wave" height={"40px"} width={"100%"} />
          <Skeleton animation="wave" height={"40px"} width={"100%"} />
          <Skeleton animation="wave" height={"40px"} width={"100%"} />
          <Skeleton animation="wave" height={"40px"} width={"100%"} />
          <Skeleton animation="wave" height={"40px"} width={"100%"} />
        </Box>
      )}
      <AllProduct allData={allData} />
    </Container>
  );
}

export default Product;
