import { Box } from "@mui/material";
import React from "react";

const TutorialVideo = ({ title }) => (
  <Box sx={{width:"80%",height:"100%"}}>
    <iframe
      width="100%"
      height="100%"
      src={`${title.videoUrl}`}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>

  </Box>
);

export default TutorialVideo;
