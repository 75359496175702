import { Box, Button, CardContent, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { MyCard, MyPrice } from "./style/price";
import { LessBtn } from "../Product_Owner/style/product";
import { Link } from "react-router-dom";
import ContactBox from "../contact/ContactSimple/ContactBox";
import ProductContact from "../Product_Owner/ProductContact/ProductContact";
const PriceCard = ({ el }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [click, setClick] = useState(false);
  function toggleAccordion() {
    setIsOpen(!isOpen);
  }

  return (
    <MyCard>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img className="priceImage" src={el.img} alt="" />
        </Box>
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            marginBottom: "20px",
            fontWeight: "600",
            fontSize: "28px",
            color: "#3333333",
          }}
        >
          {el.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <MyPrice
            style={{
              backgroundColor: el.color,
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            {el.price}
            <span style={{ fontSize: "13px" }}>{el.month}</span>
          </MyPrice>
          {/* <MyTypography2>{index.priceUnit}</MyTypography2> */}
        </Box>
        <Typography>{el.desc}</Typography>
        <div className="accordion">
          <div
            onClick={toggleAccordion}
            className={`accordion-content ${isOpen ? "open" : "closed"}`}
            id="scroll"
            style={{ maxHeight: isOpen ? "450px" : "140px", overflow: "auto" }}
          >
            <p>
              {el.list.map((elem, i) => (
                <Typography
                  key={i}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    margin: "1px 0px",
                  }}
                >
                  <CheckIcon sx={{ color: el.color, marginRight: "10px" }} />
                  <Typography sx={{ color: "gray", fontSize: "12px" }}>
                    {elem}
                  </Typography>
                </Typography>
              ))}
            </p>
            {isOpen ? (
              <LessBtn onClick={() => setIsOpen(false)}>show less</LessBtn>
            ) : (
              <p className="more-link"></p>
            )}
          </div>
        </div>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "30px 0px",
          }}
        >
          <Box>
            <Button
              sx={{
                width: "100%",
                height: "30%",
                fontSize: "16px",
                backgroundColor: el.color,
                borderRadius: "45px",
                padding: "5px 10px !important",
              }}
              variant="contained"
            >
              <Link
                style={{ color: "white" }}
                onClick={() => {
                  if (el.btn === "Contact Sales") {
                    console.log('hi');
                    setClick(!click);
                  }
                }}
                to={el.btn === "Try" && "https://app.dpsummary.com/login.html"}
              >
                {el.btn}
              </Link>
            </Button>
          </Box>
        </Box>
      </CardContent>
      <ProductContact title="Request" btn="Send" openCntc={click} setOpenCntc={setClick} detail="" id="" desc=""/>
    </MyCard>
  );
};

export default PriceCard;
