import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDataMentor } from "../../store/allMentor";
// import { getHomePageMentor } from "../../store/allMentor";
import { callApi } from "../../Api/callApi";


export default function GetData(startLimit, endLimit) {
    const dispatch = useDispatch();
  
    useEffect(() => {
      callApi("csGetProductOwnerProfileListNew", {
        startLimit: startLimit,
        endLimit: endLimit,
      }).then((res) => dispatch(getDataMentor(res?.tbl[0]?.r)));
    }, [dispatch]);
  }