import { Box, Typography } from "@mui/material";
import React from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { FooterContainer, FooterFirstChild } from "./styles/footer";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
function Footer() {
  return (
    <FooterContainer>
      <FooterFirstChild
        align="center"
        sx={{ fontSize: { xs: "12px", sm: "14px", lg: "16px" } }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: { xs: "12px", sm: "14px", lg: "16px" },
          }}
        >
          {" "}
          <CopyrightIcon /> Copyright 2023 dpsummary.com{" "}
        </Typography>{" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <a className="alink" href="mailto:info@dpsummary.com">
            info@dpsummary.com
          </a>
        </Box>
      </FooterFirstChild>
      <FooterFirstChild sx={{width:"86%",margin:"0 auto"}}>
        <p>Address: 15551, Sepapaja 6, Tallinn, Estonia</p>
        {/* <a className="alink"  target="_blank" href="https://www.facebook.com/dpsummary">
          <FacebookIcon />
        </a>
        <a className="alink" target="_blank" href="https://www.linkedin.com/company/dpsummary/">
          <LinkedInIcon />
        </a> */}
      </FooterFirstChild>
    </FooterContainer>
  );
}

export default Footer;
