import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Alltutorials from "../components/Tutorials/Alltutorials";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { tutorials } from "../components/Tutorials/data";
import { fetchTutorial } from "../store/allTutorilas";
import TutorialsCard from "../components/Tutorials/TutorialsCard";

const SingleTutorialsForCateg = () => {
  const [id, setId] = useState(null);
  const [title, setTitle] = useState(null);
  const param = useParams();
  const data = useSelector((state) => state.tutorials);
  const dispatch = useDispatch();
  //  const title=tutorials.filter((el)=>el.id===param.id)
  useEffect(() => {
    setId(param.id);
  }, [param.id]);
  // console.log(data);
  useEffect(() => {
    // console.log(id);
    if (id) {
      const tutorial = tutorials.find((el) => el.id === id);
      dispatch(fetchTutorial());
      setTitle(tutorial.title);
    }
  }, [id, tutorials, dispatch]);

  return (
    <>
      <Typography
        sx={{
          fontSize: "30px",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom:"20px",
          
        }}
      >
        {title ? title : <></>}
      </Typography>
      <Box sx={{ maxWidth: "60%", margin: "0 auto" }}>
      <Grid container spacing={4}>
      {data?.allTutorials?.tbl?.[0]?.r &&
        data.allTutorials.tbl[0]?.r.map((el, i) => {
          if (el.videoCategory === title) {
            return (
              <TutorialsCard el={el} i={i}/>
            );
          }
        })}
        </Grid>
    </Box>
    </>
  );
};

export default SingleTutorialsForCateg;
