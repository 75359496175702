import React from "react";
import { Box, Button, Typography, createTheme, styled } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import slider1Img from "../../assets/SliderImage/BC.jpg";
import slider2Img from "../../assets/SliderImage/BL-SM.jpg";
import slider3Img from "../../assets/SliderImage/PC.jpg";
import slider4Img from "../../assets/SliderImage/SC.jpg";

function Part3() {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  const navigate = useNavigate();

  // const handleButtonClick = () => {
  //   window.scrollTo({
  //     top: document.documentElement.scrollHeight,
  //     behavior: "smooth",
  //   });
  // };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1C2128",
        second: "#888888",
      },
      secondary: {
        main: "#373C44",
      },
    },
  });

  const MyTypography4 = styled(Typography)({
    color: theme.palette.primary.second,
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Open Sans",
    "@media (max-width:665px)": {
      fontSize: "16px",
    },
    marginBottom: "20px",
  });

  const MyButton = styled(Button)({
    fontSize: "16px",
    height: "56px",
    width: "222px",
    backgroundColor: "#4691db",
    color: "white",
    fontFamily: "Open Sans",
    fontWeight: 700,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#4691db",
      color: "white",
    },
    "@media (max-width:665px)": {
      width: "150px",
      height: "45px",
    },
  });

  const myImage = {
    width: "100%",
    objectFit: "cover",
  };

  return (
    <>
      <MyTypography4 style={{width:"62%",textAlign:"center",margin:"0 auto"}} align="center">
        Project Details, Digital Services, User Story Card, Live Prototype/MVP,
        Inputs & Descriptions, Input - API Relation, Input - Database Relation,
        User Story Flow, API Card, API Management,API Configuration, API Test
        Case, Database Card, Entity Diagram, Information Flow Architecture, GIT
        Integration, Figma, Draw.io, Google Drive Integration
      </MyTypography4>
      {/* <Container sx={{margin:0, padding:0}}> */}
      {/* Addition Part Start */}
      <Box sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 3 }}>
        <MyButton
          onClick={() => navigate("/samples")}
          sx={{ textTransform: "none" }}
        >
          View Samples
        </MyButton>

        <MyButton sx={{ textTransform: "none" }}>
          {" "}
          <Link style={{ color: "white" }} to="http://app.dpsummary.com/">
            Try it free{" "}
          </Link>
        </MyButton>
      </Box>
      {/* <Box sx={{display:"flex", justifyContent:"center"}}>

        <MyButton>Contact Us</MyButton>
        </Box> */}

      <Box
        sx={{
          maxWidth: "800px",
          margin: "0 auto",
          marginTop: "38px",
          marginBottom: "78px",
          display: "flex",
          justifyContent: "center",
          border: "1px solid lightgray",
          padding: "5px",
          borderRadius: "3px",
        }}
      >
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          pagination={pagination}
          navigation
          modules={[Pagination, Navigation]}
          className="mySwiper1"
          loop={true}
        >
          {/* Slider1 start */}
          {/*  */}
          <SwiperSlide>
            <Box>
              <img style={myImage} src={slider1Img} alt="" />
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box>
              <img style={myImage} src={slider2Img} alt="" />
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box>
              <img style={myImage} src={slider3Img} alt="" />
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box>
              <img style={myImage} src={slider4Img} alt="" />
            </Box>
          </SwiperSlide>

          {/* Slider1 end */}
          {/*  */}
        </Swiper>
      </Box>
    </>
  );
}

export default Part3;
