import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productDetail: [],

};

const productDetail = createSlice({
  name: "productDetail",
  initialState,
  reducers: {
    getProductDetail: (state, action) => {
      state.productDetail = action.payload;
    },

  },
});

export const { getProductDetail } = productDetail.actions;

export default productDetail.reducer;
