import styled from "@emotion/styled";
import { Box } from "@mui/material";
export const myListButton = {
    width: "90px",
    height: "30px",
    backgroundColor: "#F36E26",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3px",
    color: "white",
    fontSize: "14px",
    padding: "0 3px",
    textDeceration: "none",
  };
  export const myListButton3 = {
    width: "160px",
    height: "30px",
    backgroundColor: "#F36E26",
    fontWeight: "600",
    // backgroundColor:"#9b0a0a",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3px",
    color: "white",
    marginTop: "10px",
    fontSize: "13px",
    padding: "0 3px",
    "@media screen and (minWidth: 992px) and (maxWidth: 1200px)": {
      fontSize: "12px",
    },
  };

  export const myListButton1 = {
    color: "black",
    transition: 5,
    // fontSize:"14px"
  };
  export const myListButton11 = {
    color: "white",
    transition: 5,
    backgroundColor:"#387CB7",
    padding:"3px 2%",
    borderRadius:"5px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
    // fontSize:"14px"
  };

  // export const [isHovered, setIsHovered] = useState(false);

  // export const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // export const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  export const MobileBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    minWidth: "200px",
    marginTop: "20px",
  });

  export const MobileLink = {
    display: "none",
  };