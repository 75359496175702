import businescase from '../../assets/image/Part1Icon.cf1fad0287ff47c52c46.png'
import rolemap from '../../assets/image/download.png'
import userstory from '../../assets/image/storycard.png'
import storyflow from '../../assets/image/Flow-Icon.388791c07b038d825bde.webp'
import backlog from '../../assets/image/download (2).png'
import processcard from '../../assets/image/Process.1794e50c8bda0d1e57f5.png'
import api from '../../assets/image/Api-Icon.520c983a55cb87c6ff42.png'
import db from '../../assets/image/db.png'
export const tutorials=[
    {
        img:businescase,
        title:"Business Case",
        id:"business-case"
    },
    {
        img:userstory,
        title:"User Story Card",
        id:"user-story-card"
    },
    {
        img:storyflow,
        title:"User Story Flow",
        id:"user-story-flow"
    },
    {
        img:rolemap,
        title:"Role Map Diagram",
        id:"role-map-diagram"
    },
    {
        img:backlog,
        title:"Backlog/Sprint Matrix",
        id:"backlog"
    },
    {
        img:api,
        title:"API Card",
        id:"api-card"
    },
    {
        img:db,
        title:"Database Card/Entity Diagram",
        id:"database-card"
    },
    {
        img:processcard,
        title:"Process Card",
        id:"process-card"
    },
]