import styled from "@emotion/styled";
import { Box } from "@mui/material";
export const FooterContainer = styled(Box)({
    background: "#1C2128",
    padding: 3,
    width:"100%",
    marginTop: "100px",
    
  });
export const FooterFirstChild = styled(Box)({
    color: "white",
    display: "flex",
    justifyContent: "center",
    gap: 10,
    padding:"10px 0px 0px 0px"
    
  });
