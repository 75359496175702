import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header/Header";
import Home from "./pages/Home";
// import ContactSimple from "./pages/contact/ContactSimple/ContactSimple";
import Price from "./pages/Price";
import AllCourse from "./pages/AllCourse";
import Footer from "./components/Footer/Footer";
import LoadToTop from "./utils/LoadToTop/LoadToTop";
// import AllTeam from "./pages/AllTeam_Page/AllTeam";
import Profile from "./pages/Profile";
import Product from "./pages/Product";
import Training from "./pages/Training"
import InfoTraining from "./pages/InfoTraining";
// import Certificates from "./pages/Certificates";
import { Box } from "@mui/material";
// import  CertificateInfo  from "./pages/Certificateinfo";
import Tutorial from "./pages/Tutorial";
import SingleTutorialsForCateg from "./pages/SingleTutorialsForCateg";
import SingleTutorial from "./pages/SingleTutorial";
function App() {
  return (
    <div className='App'>
      <LoadToTop />
      <Header />
      <Box sx={{width:"100%",margin:"0 auto"}}  className="main-content">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/price' element={<Price />} />
          <Route path='/samples' element={<AllCourse />} />
          {/* <Route path='/polist' element={<AllTeam />} /> */}
          <Route path="/training" element={<Product />} />
          <Route path="/tutorial" element={<Tutorial />} />
          <Route path="/tutorial/:id" element={<SingleTutorialsForCateg />} />
          <Route path="/single-tutorial/:id" element={<SingleTutorial />} />
          <Route path='/profile/:id' element={<Profile />} />
          <Route path='/topics' element={ <Training />} />
          <Route path='/trainingInfo/:id' element={<InfoTraining />} />
          {/* <Route path='/certificate' element={<Certificates />} /> */}
          {/* <Route path='/certificate/:id' element={<CertificateInfo />} /> */}
        </Routes>
      </Box>
      {/* <Home/> */}
      <Footer className='footer'/>
    </div>
  );
}

export default App;
