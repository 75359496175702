import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Desc } from "./style/tutorial";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link, useNavigate } from "react-router-dom";
const TutorialHeader = ({data}) => {
    const navigate=useNavigate();
  return (
    <Box
    className="tutorialcard-cont"
      sx={{ margin: "1% 0%", display: "flex", justifyContent: "space-between" }}
    >
      <Box>
        <Typography sx={{ display: "flex", alignItems: "center", gap: "4 %" }}>
          {" "}
          <img style={{ width: "40px",marginRight:"10px" }} src={data.img} alt="" />{" "}
          <Typography sx={{ fontSize: "30px" }}>{data?.title}</Typography>{" "}
        </Typography>
        <Desc>Curated courses packed together from expert tutors</Desc>
      </Box>
      <Box  sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <Button onClick={()=>{navigate(`/tutorial/${data.id}`)}} sx={{ backgroundColor: "#40a944", color: "white",display:"flex",alignItems:"center",width:"100%" }}>
          <Link to={`/tutorial/${data.id}`} style={{color:"white"}}>View More</Link >{" "}
          <ArrowForwardIcon sx={{ color: "white", marginLeft: "4px" }} />
        </Button>
      </Box>
    </Box>
  );
};

export default TutorialHeader;
