import { configureStore } from "@reduxjs/toolkit";
import allCourse from "./allCourse";
import allMentor from "./allMentor";
import productDetail from "./productDetail";
import productsBoolean from "./productsBoolean";
import serviceList from "./allServiceList";
import allTrainingList from "./allTraining";
import allTutorilas from "./allTutorilas";

export const store = configureStore({
  reducer: {
    allCourse: allCourse,
    allMentor: allMentor,
    productDetail: productDetail,
    productsBoolean: productsBoolean,
    serviceList: serviceList,
    allTrainingList: allTrainingList,
    tutorials:allTutorilas
  },
});
