import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    allCourse:[],
    homePage:[]

}

const allCourse = createSlice({
  name: "allCourse",
  initialState,
  reducers: {
    getData:(state, action)=>{
      state.allCourse = action.payload
    },
    getHomePage:(state,action)=>{
      state.homePage = action.payload
    },

  }
});

export const {getData,getHomePage} = allCourse.actions

export default allCourse.reducer