import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productsBoolean: {
    // product2: false,
    // product3: false,
    // product4: false,
    // product5: false,
    // product6: false,
    // product7: false,
    // product8: false,
  },
};

const productsBoolean = createSlice({
  name: "productsBoolean",
  initialState,
  reducers: {
    setProductValue: (state, action) => {
      const { key, value } = action.payload;
      state.productsBoolean[key] = value;
    },

    changeProductValue: (state, action) => {
      const { key, value } = action.payload;
      state.productsBoolean[key] = !value;
    },

    allTrueValue: (state) => {
      Object.keys(state.productsBoolean).forEach(key => {
        state.productsBoolean[key] = true;
      });
    },
    allFalseValue: (state) => {
      Object.keys(state.productsBoolean).forEach(key => {
        state.productsBoolean[key] = false;
      });
  
    },
  },
});

export const {
  setProductValue,
  changeProductValue,
  allTrueValue,
  allFalseValue,
} = productsBoolean.actions;

export default productsBoolean.reducer;
