import { Container, Typography, createTheme, styled } from "@mui/material";
import React from "react";
import Percent from "../components/Mainpage.jsx/Percent";
import ContactSimple from "../components/contact/ContactSimple/ContactSimple";
import Part1 from "../components/Mainpage.jsx/Part1";
import Part3 from "../components/Mainpage.jsx/Part3";
import MentorMain from "../components/Mentor_part/MentorMain";
// import CommingTraining from "../components/Mainpage.jsx/UpCommingTraining/CommingTraining";
import { Link } from "react-router-dom";
import Partners from "../components/OurPartners/Partners";
import TutorialsMain from "../components/Tutorials/TutorialsMain";

function Home() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#333333",
        second: "#888888",
      },
      secondary: {
        main: "#373C44",
      },
    },
  });

  const MyTypography = styled(Typography)({
    color: theme.palette.primary.main,
    fontSize: 28,
    fontWeight: 700,
    fontFamily: "Open Sans",
    textAlign: "center",
    margin: "50px 30px 20px ",  
    marginTop:"150px",
    "@media (maxWidth:665px)": {
      fontSize: "20px",
    },
  });

  const MyTypography4 = styled(Typography)({
    color: theme.palette.primary.second,
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Open Sans",
    "@media (max-width:665px)": {
      fontSize: "16px",
    },
    marginBottom: "20px",
  });

  return (
    <React.Fragment>
      <Container>
        <MyTypography sx={{marginTop:"60px !important"}}>
          <h1 style={{ fontSize: "28px" }}>
            Digital Product Summary™ is a Guide for Documentation, <br /> Software
            Development & Project Management
          </h1>
        </MyTypography>

        <Part3 />

        {/* Part3 */}

        {/* Part1 */}
        <MyTypography>
          <h2 style={{ fontSize: "30px" }}>
            What is Digital Product Summary™?
          </h2>
        </MyTypography>
        <MyTypography4>
          Digital Product Summary™ is the only standardized and structured
          <br />
          documentation form that includes every details of the digital product.
        </MyTypography4>

        <Part1 />

        {/* <MyTypography>
          <Link to="/training" style={{ color: "black" }}>
            Upcoming Trainings
          </Link>
        </MyTypography> */}

        {/* <CommingTraining /> */}
        {/* Part2 */}

        <MyTypography>
          <Link to="/samples" style={{ color: "black" }}>
            <h2 style={{ fontSize: "30px" }}>
              Digital Product Summary™ Samples
            </h2>
          </Link>
        </MyTypography>
        <MyTypography4>
          Discover Different Types of the Summary Samples
        </MyTypography4>
        <MentorMain />

        <MyTypography>
          <Link to="/tutorial" style={{ color: "black" }}>
            <h2 style={{ fontSize: "30px" }}>Tutorials</h2>
          </Link>
        </MyTypography>
        <MyTypography4>
        Details of the Digital Product Summary'™ system can be learnt from the video tutorials.
        </MyTypography4>
        <TutorialsMain />

        <MyTypography>
          <h5 style={{ fontSize: "28px", color: "black" }}>Our Partners</h5>
        </MyTypography>
        <MyTypography4>
        We are getting bigger and bigger.
        </MyTypography4>
        <Partners />
        <MyTypography>
          <h2 style={{ fontSize: "30px" }}>Why Digital Product Summary™?</h2>
        </MyTypography>

        <MyTypography4 >
          We have developed a breakthrough, innovative and , <br /> AI based
          simulation platform in order to create a structured, standardized,
          detailed <br /> Digital Product Documentation to makes others life easy with
          the  following <br /> advantages.  After 12+ years of the research we have following:
        </MyTypography4>

        {/* Part4 */}
        <Percent />

        <ContactSimple />
      </Container>
    </React.Fragment>
  );
}

export default Home;
