import { Box, Grid, Skeleton, Typography, styled } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import GetData from "../Api/getData";

import MentorCard from "../components/Mentor_part/MentorCard";

function AllCourse() {
  GetData(0, 99);
  const { allCourse } = useSelector((state) => state.allCourse);

  const MyTypography = styled(Typography)({
    color: "#333333",
    fontSize: 28,
    fontWeight: 700,
    fontFamily: "Open Sans",
    textAlign: "center",
    "@media (maxWidth:665px)": {
      fontSize: "20px",
    },
    margin: "50px 0px ",
  });

  return (
    <>
      <Box sx={{ maxWidth: "800px", margin: "0 auto", minHeight: "70vh" }}>
        <MyTypography sx={{ cursor: "pointer" }}>
          Digital Product Summary Samples
        </MyTypography>

        {/* <MentorMain /> */}
        <Grid
          container
          spacing={4}
          sx={{ padding: { xs: "0px 15px", sm: "auto" } }}
        >
          {allCourse.length !== 0 ? (
            allCourse?.map((index, key) => (
              <Grid
                className="summary-samples"
                key={key}
                sx={{ minHeight: "360px" }}
                item
                xs={12}
                sm={4}
              >
                <MentorCard index={index} />
              </Grid>
            ))
          ) : (
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <Skeleton
                  animation="wave"
                  pt={0}
                  height={"250px"}
                  width={"100%"}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton
                  animation="wave"
                  height={"250px"}
                  m={0}
                  width={"100%"}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton animation="wave" height={"250px"} width={"100%"} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton animation="wave" height={"250px"} width={"100%"} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton animation="wave" height={"250px"} width={"100%"} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton animation="wave" height={"250px"} width={"100%"} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default AllCourse;
