import { Box, Typography, styled } from "@mui/material";
import React, {  useEffect, useState, useCallback, useMemo } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ProductContact from "./ProductContact/ProductContact";
import { useDispatch } from "react-redux";
import { setProductValue } from "../../store/productsBoolean";
import {
  Typography1,
  Box4,
  Typography3,
  Box5,
  BoxLetter,
  PriceBox,
} from "./style/productCard";

const ProductCard = ({ detail, colorMassiv, open, number }) => {
  const [value, setValue] = useState(false);
  const dispatch = useDispatch();
console.log(open);
  useEffect(() => {
    dispatch(setProductValue({ key: number, value: false }));
    if(open){
      setValue(true)
    }else{
      setValue(false)
    }
  }, [open]);

  const MainTypography = styled(Typography)({
    padding: "6.3px 10px ",
    textAlign: "left",
    fontSize: "22px",
    fontFamily: "Open Sans",
    background: "#e4e4e4",
    borderLeft: `4px solid ${colorMassiv[number]}`,
    borderBottom: ` 1px solid ${colorMassiv[number]}`,
    fontWeight: "600",
    color: "#333333",
  });

  const [openCntc, setOpenCntc] = useState(false);

  const handleClick = useCallback(() => {
    setValue((prevValue) => !prevValue);
  }, []);

  const RawHtml = useMemo(
    () => ({ children = "", tag: Tag = "div", ...props }) => (
      <Tag {...props} dangerouslySetInnerHTML={{ __html: children }} />
    ),
    []
  );

  return (
    <>
      <BoxLetter sx={{ width: "100%" }}>
        <MainTypography>{detail?.stepName}</MainTypography>
        <ProductContact
          openCntc={openCntc}
          setOpenCntc={setOpenCntc}
          detail={detail}
        />
        <Box4>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PriceBox>
              <Typography1>You will learn:</Typography1>
            </PriceBox>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {value ? (
                <Typography3 sx={{}} onClick={() => handleClick()}>
                  {" "}
                  More <KeyboardArrowUpIcon />{" "}
                </Typography3>
              ) : (
                <Typography3 onClick={() => handleClick()}>
                  {" "}
                  More <KeyboardArrowDownIcon />{" "}
                </Typography3>
              )}
            </Box>
          </Box>

          <Box sx={{ position: "relative" }}>
            <Box>
              {value ? (
                <Box sx={{ padding: " 15px 10px" }}>
                  <Box5>
                    <Box sx={{ paddingLeft: "12px", marginTop: "15px" }}>
                      <RawHtml tag={"span"} style={{ fontSize: "12px" }}>
                        {detail?.youWillLearn}
                      </RawHtml>
                    </Box>
                  </Box5>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box4>
      </BoxLetter>
    </>
  );
};

export default ProductCard;
