import styled from "@emotion/styled";
import { Typography,Box } from "@mui/material";
export const LessBtn = styled(Typography)({
    color:"gray",
    textAlign:"center",
    fontWeight:"700",
    fontSize:"14px",
    paddingTop:"5px",
    cursor:"pointer"
})
export const Container = styled(Box)({
    width:"75%", 
    margin: "0 auto",
    padding:"0px 4%",
    "@media (max-width:625px)": {
      width:"90%",
      padding:"0"
    },
  });
  