import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const CloseBtn = styled(Box)({
    width: "100%",
    cursor:"pointer",
    backgroundColor: "gray",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 20px",
    borderRadius: "4px",
    color: "white",
    fontWeight:"600",
    fontSize: "0.935rem",
    lineHeight: "1.75",
});
export const BtnsBox = styled(Box)({
    width: "100%",
    cursor:"pointer",
    display: "flex",
    marginLeft:"2%",
    gap:"2%",
    marginTop:"2%",
    alignItems: "center",
    padding: "6px 16px",
    borderRadius: "4px",
    color: "white",
    fontSize: "0.875rem",
    lineHeight: "1.75",
});