import styled from "@emotion/styled";
import { Typography,Box } from "@mui/material";

export const myImage = {
    width: "100%",
    borderRadius: "10%",
};
export const Cont =styled(Box)( {
    margin: "30px 0px",
    "@media (max-width:665px)": {
        margin:"0px 15px",
    },
});

export const JobTypo = styled(Box)({
    fontSize: "16px",
    fontWeight: "600",
    color: "#8686A0",
    fontFamily: "Open Sans",
});

export const PositionTypo = styled(Box)({
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Open Sans",
    margin: "10px 0",
});

export const AboutTypo = styled(Box)({
    color: "#747474",
});

export const NameTypo = styled(Box)({
    fontSize: "22px",
    margin: "10px 0",
    fontFamily: "Open Sans",
    fontWeight: "700",
    color: "#333333",
});