import axios from "axios";
export async function getPartnerList() {
    try {
      const response = await axios.post(`https://apimekan.com/sourced_agile/api/post/calle/DpsGegPartnerList`,{});
      const data = response.data;
      return data;
    } catch (error) {
      console.log(error.message);
      return error;
    }
  }
  