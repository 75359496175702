import * as yup from "yup";
export const validationSchema = yup.object({
    firstName: yup
      .string()
      .required("First name is required")
      .min(3, "Please enter a name more than 1 character"),
    lastName: yup
      .string()
      .required("Last name is required")
      .min(3, "Please enter a name more than 1 character"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email name is required"),
    multiline: yup
      .string()
      .required("Write your question")
      .min(5, "Must be more than 10 characters"),
  });
