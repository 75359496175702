import * as yup from "yup";
export const validationSchema = yup.object({
    fullName: yup
      .string()
      .required("FullName name is required")
      .min(3, "Please enter a name more than 1 character"),
    country: yup
      .string()
      .required("Country is required")
      .min(3, "Please enter a name more than 1 character"),
    organization: yup
      .string()
      .required("organization is required")
      .min(3, "Please enter a name more than 1 character"),
    position: yup
      .string()
      .required("positionis required")
      .min(3, "Please enter a name more than 1 character"),
    number: yup
      .number()
      .typeError("Mobile Number is required")
      .required("Number is required")
      .min(0, "Please enter mobile number character"),
    whatsapp: yup
      .number()
      .typeError("Whatsapp Number is required")
      .required("Whatsapp Number is required")
      .min(0, "Please enter whatsapp number character"),

    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email name is required"),
    comment: yup
      .string()
      .required("Write your question")
      .min(5, "Must be more than 10 characters"),
  });