import styled from "@emotion/styled";
import { Typography, Box } from "@mui/material";
export const MyTypography = styled(Box)({
    color: "#333333",
    fontSize: 22,
    fontWeight: 700,
    fontFamily: "Open Sans",
    margin: "40px 0px 10px",
   
});
export const Container = styled(Box )({
    margin: "0 auto",
    "@media (max-width:665px)": {
        margin:"0px 15px",
    },
    "@media (max-width:965px)": {
        margin:"0px 15px",
    },
});
export const TimeTypo = styled(Box)({
    fontWeight: 700,
    color: "#333333",
    fontFamily: "Open Sans",
    fontSize: "20px",
});
export const TimeTypo2 = styled(Box)({
    color: "#333333",
    fontFamily: "Open Sans",
    fontWeight: "500",
    fontSize: "14px",
});
export const Box2 = styled(Box)({
    marginBottom: "40px",
    color: "#333333",
    // color:"lightgray",
    fontFamily: "Open Sans",
    fontWeight: "500",
    fontSize: "15px",
    padding: "0 10px",
});

export const MyBox2 = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "60vh",
    "@media (max-width:565px)": {
        minHeight: "110vh"
    },
    "@media (max-width:1065px)": {
        minHeight: "40vh"
    },
});

export const MyBox3 = styled(Box)({
    padding: "7px",
    minHeight: "90px",
    padding:"10px 0px 0px 10px",
    background: "#FFF2CC",
});
export const DiscountPrice = styled(Box)({
    fontWeight: 700,
    // color: "#333333",
    fontFamily: "Open Sans",
    // fontSize: "20px",
    display:"inline",
    marginLeft:"5px",
    color: "red",
    textDecoration: "line-through",
    fontSize: "14px",
    marginBottom: "2px",

});

export const Join = styled(Box)({
    padding: "7px",
    minHeight: "70px",
    background: "#FFF2CC",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#DAE8FC",
    border: "1px solid #6C8EBF",
    cursor: "pointer",
})
export const SectionTitle = styled(Box)({
    fontSize: "20px",
    padding: "10px 0px",
    margin:"15px 0px",
    fontWeight: "600"
})
export const MainTitles = styled(Box)({
    color: "#333333",
    fontWeight: 700,
    fontFamily: "Open Sans",
    margin: "40px 0px 10px",
    fontSize: "28px",
    textAlign: "center",
    "@media (maxWidth:665px)": {
        fontSize: "20px",
    },
})
export const DescTitles = styled(Box)({
    color: "#333333",
    fontFamily: "Open Sans",
    margin: "40px 0px 10px",
    fontSize: "27px",
    fontWeight: "500",
    textAlign: "center",
  
})
export const Price = styled(Box)({
    padding: "7px",
    minHeight: "70px",
    background: "#FFF2CC",
    position: "relative",
    backgroundColor: "#F5F5F5",
    border: "1px solid #666666",
    
})
export const JoinText = styled(Box)({
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    display: "inline",
    fontWeight: "700",
    color: "#333333",
    fontFamily: "Open Sans",
    fontSize: "20px",
})
