import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InsertNewRegistration } from "../../../utils/crud/insertNewRegistration";
import { CloseBtn, BtnsBox } from "./style/panel";
import { validationSchema } from "../../../utils/schemas/tabpanelvalidation";
function TabPanel(index) {
  let { title, productsName, desc, id, buttonName } = index;
  const [res, setRes] = useState(null);
  useEffect(() => {
    if (typeof productsName === "string") {
      setRes(`${desc} ${productsName} ${id}`);
    } else {
      setRes(productsName.toString());
    }
  }, [res]);
  const filedStyle = {
    width: "100%",
  };

  const notify = () =>
    toast.success(
      " You have registered successfully. We will contact you in a short time.",
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );

  const formik = useFormik({
    initialValues: {
      productName: `${productsName} ${id} ${desc}`,
      fullName: "",
      country: "",
      organization: "",
      position: "",
      number: "",
      whatsapp: "",
      email: "",
      comment: "",
      desc: `${desc}`,
      fkTrainingId: id,
      title: `${title}`,
    },
    onSubmit: (values, { resetForm }) => {
      InsertNewRegistration({
        ...values,
        fkTrainingId: id,
        mobile: values.number,
        fullname: values.fullName,
      }).then(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
          return;
        }
      );
      resetForm({ values: "" });
      console.log({ values });
      emailjs
        .send(
          "service_j3bjl59",
          "template_9pfknqp",
          { ...values },
          "UFF_E1n1rDlHxDoHz"
        )

        .then(
          (result) => {
            // console.log(result.text);
            notify();
          },
          (error) => {
            // console.log(error.text);
          }
        );
    },
    validationSchema: validationSchema,
  });

  const styles = {
    input: {
      background: "red",
      borderRadius: 10, // or any other value
    },
  };

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        inputProps={{ style: styles.input }}
      />
      <Box sx={{ padding: "0px 10px" }}>
        <form
          className="simpleForm"
          sx={{ gap: "20px" }}
          onSubmit={formik.handleSubmit}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                className="inputRounded"
                name="fullName"
                id="fullName"
                label="Full Name"
                comment
                maxRows={4}
                value={formik.values.fullName}
                onChange={formik.handleChange}
                error={
                  formik.touched.fullName && Boolean(formik.errors.fullName)
                }
                helperText={formik.touched.fullName && formik.errors.fullName}
                style={filedStyle}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                className="inputRounded"
                name="email"
                type="email"
                id="email"
                label="Email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                style={filedStyle}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                className="inputRounded"
                name="number"
                type="number"
                id="number"
                label="Mobile Number"
                variant="outlined"
                value={formik.values.number}
                onChange={formik.handleChange}
                error={formik.touched.number && Boolean(formik.errors.number)}
                helperText={formik.touched.number && formik.errors.number}
                style={filedStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className="inputRounded"
                name="whatsapp"
                type="number"
                id="whatsapp"
                label="Whatsapp Number"
                variant="outlined"
                value={formik.values.whatsapp}
                onChange={formik.handleChange}
                error={
                  formik.touched.whatsapp && Boolean(formik.errors.whatsapp)
                }
                helperText={formik.touched.whatsapp && formik.errors.whatsapp}
                style={filedStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className="inputRounded"
                name="country"
                id="country"
                label="Country"
                variant="outlined"
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
                style={filedStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className="inputRounded"
                name="organization"
                id="organization"
                label="Organization"
                variant="outlined"
                value={formik.values.organization}
                onChange={formik.handleChange}
                error={
                  formik.touched.organization &&
                  Boolean(formik.errors.organization)
                }
                helperText={
                  formik.touched.organization && formik.errors.organization
                }
                style={filedStyle}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                className="inputRounded"
                name="position"
                id="position"
                label="Position"
                variant="outlined"
                value={formik.values.position}
                onChange={formik.handleChange}
                error={
                  formik.touched.position && Boolean(formik.errors.position)
                }
                helperText={formik.touched.position && formik.errors.position}
                style={filedStyle}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                className="inputRounded"
                id="outlined-comment-flexible"
                label="Your comment..."
                name="comment"
                multiline
                value={formik.values.comment}
                onChange={formik.handleChange}
                error={
                  formik.touched.position && Boolean(formik.errors.comment)
                }
                helperText={formik.touched.comment && formik.errors.comment}
                comment
                rows={4}
                style={filedStyle}
              />
            </Grid>

            <TextField
              sx={{ display: "none" }}
              className="inputRounded"
              id="outlined-comment-flexible"
              label="Your comment..."
              name="productName"
              value={formik.values.comment}
            />
            <TextField
              sx={{ display: "none" }}
              className="inputRounded"
              id="outlined-comment-flexible"
              label="Your comment..."
              name="title"
              value={`${title}`}
            />

            {/* {
              location.pathname == ''
            } */}

            <BtnsBox>
              <Box item>
                <Button
                  color="primary"
                  variant="contained"
                  // fullWidth
                  type="submit"
                >
                  {buttonName}
                </Button>
              </Box>
              <Box item>
                <CloseBtn
                  onClick={() => {
                    window.location.reload();
                  }}
                  color="inherit"
                  variant="contained"
                  type="submit"
                >
                  CLOSE
                </CloseBtn>
              </Box>
            </BtnsBox>
          </Grid>
        </form>
      </Box>
    </React.Fragment>
  );
}

export default TabPanel;
