
import React from "react";
import PriceCard from "../components/Price/PriceCard";
import { priceData } from "../components/Price/data";
import { Cont } from "../components/Price/style/price";
function Price() {
  return (
    <Cont >
       {priceData.map((el,i)=>(
         <PriceCard el={el} key={i}/>
       ))}
        
    </Cont>
  );
}

export default Price;
