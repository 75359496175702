import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getPartnerList } from "../../utils/crud/getPartnerList";
import { Link } from "react-router-dom";
const Partners = () => {
  const [partners, setPartners] = useState([]);
  async function getPartners() {
    let data = await getPartnerList();
    setPartners([...data.tbl[0].r]);
  }
  useEffect(() => {
    getPartners();
  }, []);
  const renderSlides = () =>
    partners.map((el, i) => (
      <div key={i}>
        <Link
          target="_blank"
          to={el.webSite && el.webSite}
          style={{
            textAlign: "center",
            height: "20vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <img style={{ width: "30%" }} src={el?.imageUrl} alt="" />
          <Link style={{ color: "black", fontWeight: "bold" }}>
            {el?.title}
          </Link>
        </Link>
      </div>
    ));

  return (
    <div style={{ margin: "0% auto", width: "75%", padding: "0% 4%" }}>
      <Slider autoplay autoplaySpeed={2500} slidesPerRow={3} dots={true}>
        {renderSlides()}
      </Slider>
    </div>
  );
};

export default Partners;
