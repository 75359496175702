import React from 'react'

function MentorCard({index}) {
  
  // const goToAllPage = () => {
  //   navigate("/allCourse");
  // };
  return (
    <>
    <div className='mainMentor'>
                <div className='mentorImg'>
                  <img src={index?.headingFileUrl} alt='' />
                </div>
                <div  className='mentorLetter'>
                  <p>{index?.projectTitle}</p>
                 <p onClick={()=>  window.open(`https://dpsummary.com/templates/${index?.urlProjectId}/index.html` , '_blank')} >{index?.serviceCategoryName}</p>

                </div>
                <div className='mentor'>
                 
                  {/* <img src={index?.userProfileUrl} alt='' /> */}
                  {/* <Link  style={myLink}  to={`/profile/${index?.fkProductOwnerProfileId
}`} >

                  <p>  {index?.authorFullname}</p>
                  </Link> */}
             <div className='mentorTime'>

             <i className="fa-solid fa-calendar-days"></i>
                  {index?.uploadDate}
             </div>
                
            
                {
                  index?.showPrototype !== "0" && (<div className='mentorButton '>
                  <p onClick={()=> window.open(`https://dpsummary.com//templates/${index?.urlProjectId}/view.html?sc=${index?.urlViewId}` , '_blank') }  className='mentorBtn'> <i className="fa-solid fa-play"></i>  Prototype</p>
                </div>)
                }
                </div>
              </div>
    
    
    </>
  )
}

export default MentorCard