import {
  AppBar,
  Box,
  Drawer,
  Grid,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/image/LOGO of HEKAYAM.com-Logo.drawio.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { myListButton1, MobileBox, myListButton11 } from "./styles/header";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
function Header() {
  const navigate = useNavigate();
  const goTo = () => {
    navigate("/");
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const MyToolbar = styled(Toolbar)({
    minHeight: "40px !important",
    width: "65%",
    margin: "0 auto",
    "@media (max-width:1165px)": {
      width: "88%",
    },
    
  });
  

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        sx={{ bgcolor: "#E4E4E4", boxShadow: "0", mb: "26px", height: "45px" }}
      >
        <MyToolbar>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={6} lg={4}>
                <Box
                  onClick={goTo}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    cursor: "pointer",
                    justifyContent:"space-between"
                  }}
                >
                  <Box
                    onClick={goTo}
                    component="img"
                    sx={{
                      paddingTop: "8px",
                      height: {
                        sm: 40,
                        xs: 25,
                      },
                    }}
                    alt="Your logo."
                    src={Logo}
                  />
                  <Typography sx={{ fontSize: 20 }}></Typography>
                </Box>
              </Grid>
              {/* Menu Start  */}
              <Grid item xs={4.7} lg={7}>
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    alignItems: "center",
                    justifyContent:"end",
                    gap: 3,
                    width:"100%"
                  }}
                >
                  {/* <NavLink style={myListButton1} to="/certificate">
                    Certificates
                  </NavLink> */}
                  <NavLink style={myListButton1} to="/samples">
                    Samples
                  </NavLink>
                  <NavLink style={myListButton1} to="/price">
                    Pricing
                  </NavLink>

                  <NavLink style={myListButton1} to="/training">
                    Training
                  </NavLink>
                  <NavLink style={myListButton1} to="/tutorial">
                    Tutorials
                  </NavLink>
                  <NavLink   style={myListButton11} to="https://app.dpsummary.com/login.html">
                    Go to App
                    <ArrowForwardIcon sx={{marginLeft:"5px"}}/>
                  </NavLink>
                  {/* <NavLink style={myListButton3} to="/training">
                    {" "}
                    Upcoming Trainings
                  </NavLink> */}
                </Box>
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                    justifyContent: "end",
                  }}
                >
                  <MenuIcon
                    onClick={() => handleClick()}
                    sx={{
                      color: "black",
                      fontSize: { xs: "30px", sm: "40px" },
                    }}
                  />
                </Box>
              </Grid>
              {/* Menu End */}
            </Grid>
        </MyToolbar>
        <Drawer
          aria-label="muiDrawer"
          anchor="left"
          open={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          onClick={() => setIsOpen(!isOpen)}
        >
          <MobileBox>
            <NavLink style={myListButton1} to="/">
              Home
            </NavLink>
            {/* <NavLink style={myListButton1} to="/certificate">
              Certificates
            </NavLink> */}
            <NavLink style={myListButton1} to="/samples">
              Samples
            </NavLink>
            <NavLink style={myListButton1} to="/price">
              Pricing
            </NavLink>
            <NavLink style={myListButton1} to="/training">
              Training
            </NavLink>
            <NavLink style={myListButton1} to="/tutorial">
              Tutorials
            </NavLink>
            <NavLink style={myListButton1} to="https://app.dpsummary.com/login.html">
              Go to App
            </NavLink>
            {/* <NavLink style={myListButton3} to="/training">
              Upcoming Trainings
            </NavLink> */}
          </MobileBox>
        </Drawer>
      </AppBar>
    </React.Fragment>
  );
}

export default Header;
