import axios from "axios";
export async function InsertNewRegistration(value) {
    try {
        const response = await axios.post(`https://apimekan.com/sourced_agile/api/post/calle/DpsInsertNewRegistration`, {
            "kv": value
        }

        );

        const data = response.data;
        return data;
    } catch (error) {
        return error;
    }
}