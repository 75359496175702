export const colorMassiv = {
    1: "#3399EE",
    2: "#52A300",
    3: "#D4D400",
    4: "#E87400",
    5: "#CC0000",
    6: "#000080",
    7: "#647687",
    8: "#6A24B0",
    9: "red",
    10: "blue",
    11: "orange",
    12: "yellowgreen",
    13: "#6495ED",
    14: "#DE3163",
    15: "#FF7F50",
    16: "#34495E ",
    17: "#8E44AD ",
    18: "#5D6D7E ",
    19: "#186A3B",
    20: "#D7BDE2",
  };