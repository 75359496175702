import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import img from "./letter.jpg";
import "./style.css";
import ContactBox from "./ContactBox";

function ContactSimple() {
  return (
    <Box sx={{ marginBottom: 3 }}>
      <Typography
        align='center'
        sx={{
          fontSize: "28px",
          fontWeight: 700,
          marginTop: "150px",
          marginBottom: "20px",
          color: "#333333",
        }}
      >
        CONTACT US{" "}
      </Typography>

      <Grid container>
        <Grid item xs={12} sm={12} md={5}>
          <Box sx={{ display: "flex" }}>
            <img className='contactImg' src={img} alt='' />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ContactBox />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContactSimple;
