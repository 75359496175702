import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    allMentor:[],
    homePageMentor:[]

}

const allMentor = createSlice({
  name: "allMentor",
  initialState,
  reducers: {
      getDataMentor:(state, action)=>{
        state.allMentor = action.payload
      },
      getHomePageMentor:(state,action)=>{
        state.homePageMentor = action.payload
      },
  }
});

export const {getDataMentor, getHomePageMentor } = allMentor.actions

export default allMentor.reducer