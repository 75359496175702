import { Box, styled } from '@mui/material'
import React from 'react'

function CircleProduct(index) {
  let {colorMassiv, number } =index;

  number = parseInt(number)

  const BoxCircle = styled(Box)({
    position: "relative",
    top: "41px",
    width: "9px",
    height: "8px",
    borderRadius: "50%",
    background: `${colorMassiv[number]}`,
  });

  const BoxTriangle2 = styled(Box)({
    position: "relative",
    top: "36px",
    width: "0%",
    height: "0px",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderLeft: `18px solid ${colorMassiv[number]}`,
    marginLeft: "10px",
  });

  const BoxContact = styled(Box)({
    position: "relative",
    top: "44px",
    width: "30%",
    height: "2px",
    background: `${colorMassiv[number]}`,
  });





    const MyCircle = styled(Box)({
        width:"95px",
        height:"95px",
        borderRadius:"50%",
        border:`4px solid ${colorMassiv[number]}`,
        position:"relative",
        '&::before': {
          content: "''",
          display: 'block',
          height: 50,
          width: 50,
          border:`15px solid ${colorMassiv[number]}`,
          borderRadius:"50%",
          position:"absolute",
          top:"50%",
          left:"50%",
          transform: "translate(-50%, -50%)"
        },
        '&::after': {
          content: `"${number}"`,
          fontSize:30,
          fontWeight:700,
          color: `${colorMassiv[number]}`,
          position:"absolute",
          top:"50%",
          left:"50%",
          transform: "translate(-50%, -50%)"
        }
   

      })

  return (
    <>
    <MyCircle></MyCircle>
    <BoxContact></BoxContact>
    <BoxCircle></BoxCircle>
                  <BoxTriangle2></BoxTriangle2>
    </>
  )
}

export default CircleProduct