import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const Desc = styled(Typography)({
    color: "rgb(136, 136, 136)",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Open Sans",
    "@media (max-width:665px)": {
        fontSize: "16px",
    },
    marginBottom: "20px",
    // marginLeft:"1%"
});
export const AllTutorialCont = styled(Typography)({
    width: "84%",
    margin: "2% auto"
});


