import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TabPanel1 from "./TabPanel";
import {
  Grid,
} from "@mui/material";
import ContactBox from "../../contact/ContactSimple/ContactBox";
import { TabContext, TabPanel } from "@mui/lab";

function ProductContact(props) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { openCntc, setOpenCntc, detail, id, desc,title,btn } = props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    "@media (max-width:665px)": {
      width: "90%",
    },
  };

  const handleOpen = () => setOpenCntc(true);
  const handleClose = () => setOpenCntc(false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openCntc}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openCntc}>
        <Box sx={style}>
          <Typography
            align="center"
            id="transition-modal-title"
            variant="h6"
            component="h2"
          >
            <Typography
              sx={{
                fontWeight: "bold",
                margin: "0px",
                lineHeight: "10px",
                fontSize: "22px",
              }}
            >
              {title? title :"Register"}
            </Typography>{" "}
            <br />
            <Typography
              sx={{ color: "rgba(0, 0, 0, 0.9)", lineHeight: "40px" }}
            >
              {detail} {desc} {id}
            </Typography>
          </Typography>
          <Grid
            container
            sx={{
              height: { xs: "400px", sm: "auto", md: "450px", lg: "auto" },
              overflow: { xs: "scroll", sm: "auto", md: "auto" },
            }}
          >
            <Grid item xs={12}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <TabPanel value="1">
                    <TabPanel1
                      setOpenCntc={setOpenCntc}
                      id={id}
                      desc={desc}
                      productsName={detail}
                      buttonName={btn ? btn : "Register"}
                      title="Became Product Owner"
                    />
                  </TabPanel>
                  <TabPanel value="2">
                    <ContactBox btn={btn} productsName={detail} />
                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ProductContact;
