import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tutorialApi from "../Api/tutorialApi";
export const fetchTutorial = createAsyncThunk("/modul", async () => {
    try {
        const response = await tutorialApi.post("",{});
        return response.data;
    } catch (error) {
        return { message: error.message, status: error.response };
    }
});


const initialState = {
    allTutorials:{},
    loading:"",
    error:"",
    }
    
    const allTutorials = createSlice({
        name: "tutorials",
        initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder.addCase(fetchTutorial.pending, (state) => ({
                ...state,
                loading: true,
            }));
            builder.addCase(fetchTutorial.fulfilled, (state, action) => ({
                ...state,
                loading: false,
                allTutorials: action.payload,
            }));
            builder.addCase(
                fetchTutorial.rejected,
                (state, action) =>
                (state = {
                    ...state,
                    loading: false,
                    error: action.error.message,
                })
            );
        },
    });
    
    export const {getTrainingsList} = allTutorials.actions
    
    export default allTutorials.reducer