import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getTutorialList } from "../../utils/crud/getTutorialList";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTutorial } from "../../store/allTutorilas";
import TutorialsCard from "./TutorialsCard";

function TutorialsMain() {
  const [tutorial, setTutorial] = useState([]);
  const tutorials = useSelector((state) => state.tutorials);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTutorial());
  }, [dispatch]);

  useEffect(() => {
    if (tutorials ) {
      setTutorial(tutorials?.allTutorials?.tbl?.[0]?.r);
    }
  }, [tutorials]);
    
  return (
    <Box sx={{ maxWidth: "70%", margin: "0 auto" }}>
      <Grid container spacing={4}>
        {tutorial?.map((el, i) => {
          if (i <= 2) {
            return (
              <TutorialsCard el={el} i={i}/>
            );
          }
        })}
      </Grid>
    </Box>
  );
}

export default TutorialsMain;
