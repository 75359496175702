import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TutorialHeader from "./TutorialHeader";
import { AllTutorialCont } from "./style/tutorial";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTutorial } from '../../store/allTutorilas';
import TutorialsCard from "./TutorialsCard";
// import { Link } from "react-router-dom";
const Alltutorials = ({ bool, data }) => {
  const [title, setTitle] = useState(null);
  const tutorials = useSelector((state) => state.tutorials);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTutorial());
  }, [dispatch]);

  useEffect(() => {
    if (tutorials && data) {
      const filteredTutorials = tutorials?.allTutorials?.tbl?.[0]?.r.filter(
        (el) => el.videoCategory === data.title
      );
      setTitle(filteredTutorials);
    }
  }, [tutorials, data]);

  
  return (
    <AllTutorialCont>
      {title && (
        <>
          {title.length!==0  ? <TutorialHeader data={data} /> : <></>}
          <Box className="tutorialcard-cont">
            {title.map((el, i) => {
              if (i <= 3) {
                return (
                  <TutorialsCard el={el} i={i}/>
                );
              }
            })}
          </Box>
        </>
      )}
    </AllTutorialCont>
  );
};

export default Alltutorials;
