import { createSlice } from '@reduxjs/toolkit'

const initialState = {
allTrainingList:[]
}

const allTraining = createSlice({
  name: "allTraining",
  initialState,
  reducers: {
    getTrainingsList: (state, action) => {
      state.allTrainingList = action.payload;
    },

  },
});

export const {getTrainingsList} = allTraining.actions

export default allTraining.reducer
