import { Box, Grid, Skeleton, Typography, styled } from "@mui/material";
import React from "react";
import CardTraining from "../components/Training/CardTraining";
import GetTrainingsList from "../api_calls/ApiTrainings/getData";
import { useSelector } from "react-redux";
function Training(title) {
  GetTrainingsList();

  const { allTrainingList } = useSelector((state) => state.allTrainingList);

  const MyTypographyMain = styled(Typography)({
    color: "#333333",
    fontSize: 28,
    fontWeight: 700,
    fontFamily: "Open Sans",
    textAlign: "center",
    margin: "50px 0px",

    "@media (max-width:665px)": {
      fontSize: "18px",
    },
  });
  const MyBox = styled(Box)({
    maxWidth: "800px",
    margin: "0 auto",
    "@media (max-width:665px)": {
      padding: "0 4%",
    },
  });
  return (
    <Box sx={{ minHeight: "80vh" }}>
      <MyTypographyMain align="center">Upcoming Trainings </MyTypographyMain>
      <MyBox>
        <Grid container spacing={4}>
          {allTrainingList.length !== 0 ? (
            allTrainingList.map((index, key) => (
              <Grid key={key} item xs={12} mb={3} sm={4}>
                <CardTraining index={index} />
              </Grid>
            ))
          ) : (
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <Skeleton
                  animation="wave"
                  pt={0}
                  height={"250px"}
                  width={"100%"}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton
                  animation="wave"
                  height={"250px"}
                  m={0}
                  width={"100%"}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton animation="wave" height={"250px"} width={"100%"} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton animation="wave" height={"250px"} width={"100%"} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton animation="wave" height={"250px"} width={"100%"} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton animation="wave" height={"250px"} width={"100%"} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </MyBox>
    </Box>
  );
}

export default Training;
