import { Box, Button, CardContent, Skeleton, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TodayIcon from "@mui/icons-material/Today";
import ProductContact from "../Product_Owner/ProductContact/ProductContact";

function CardTraining({ index }) {
  const [register, setRegister] = useState(false);

  const PriceTypo = styled(Box)({
    fontSize: "14px",
    fontWeight: "600",
  });
  const OldPrice = styled(Box)({
    fontSize: "10px",
    fontWeight: "600",
    position: "relative",
    top: "-2px",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "35px",
      height: "1px",
      background: "red",
      transform: "rotate(-10deg)",
      left: "3px",
      top: "7px",
    },
  });

  const DisableTypo = styled(Typography)({
    color: "#2C62A1",
    fontSize: "14px",
    fontFamily: "Open Sans",
  });

  const MyBox = styled(Box)({
    border: "1px solid lightgray",
    borderRadius: "10px",
  });

  const myImage = {
    height: "140px",
    width: "100%",
    borderRadius: "7px",
  };

  const TimeBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: 10,
    marginTop: "10px",
  });

  const MainTypo = styled(Typography)({
    margin: "10px 0px",
    fontWeight: "600",
    fontSize: "20px",
    cursor: "pointer",
    minHeight: "60px",
  });

  const TimeTypo = styled(Typography)({
    fontSize: "14px",
    color: "grey",
  });

  return (
    <Box>
      {index  ? (
        <MyBox sx={{ minHeight: "450px" }}>
          <ProductContact
            openCntc={register}
            setOpenCntc={setRegister}
            detail={`${index?.trainingTitle}, ${index?.categoryName},${index.id} `}
          />
          <CardContent sx={{ padding: "10px 8px" }}>
            <Box>
              <img src={index?.fileUrl} style={myImage} alt="" />
            </Box>
            <Typography variant="h5" component="div"></Typography>
            <Typography sx={{ paddingTop: "10px" }}>
              {index?.trainingTitle}
            </Typography>
            <Link
              className="link"
              to={{
                pathname: `/trainingInfo/${index?.id}`,
              }}
            >
              <MainTypo align="left">{index?.categoryName}</MainTypo>
            </Link>
            <TimeBox>
              <CalendarMonthIcon sx={{ color: "grey", width: "0.7em" }} />
              <TimeTypo>{index?.startDate}</TimeTypo>
            </TimeBox>
            <TimeBox>
              <TodayIcon sx={{ color: "grey", width: "0.7em" }} />
              <TimeTypo>{index?.teachingDays}</TimeTypo>
            </TimeBox>
            <Box sx={{ display: "flex", alignItems: "center", gap: 6, mt: 5 }}>
              <Button
                sx={{ fontSize: "14px", textTransform: "none", p: 0 }}
                variant="contained"
                disabled={index?.isSoldOut === "1"}
                onClick={() => setRegister(!register)}
              >
                {index?.isSoldOut === "0" ? (
                  <>Join</>
                ) : (
                  <DisableTypo>Sold Out</DisableTypo>
                )}
              </Button>
              <Box sx={{ display: "flex" }}>
                <PriceTypo>{index?.finalPrice}</PriceTypo>
                <OldPrice>{index?.discountPrice}</OldPrice>
              </Box>
            </Box>
          </CardContent>
        </MyBox>
      ) : (
        <Skeleton animation="wave" width={250} height={250}/>
      )}
    </Box>
  );
}

export default CardTraining;
