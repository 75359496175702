import {
  Box,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import Pimg1 from "./img/PercentPageImg/1.png";
import Pimg2 from "./img/PercentPageImg/2.png";
import Pimg4 from "./img/PercentPageImg/4.png";
import Pimg7 from "./img/PercentPageImg/6.png";
import digital from './img/digital.png'
import api from './img/api.webp'
import db from './img/dbicon.png'
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import issue from './img/issue.png';
import storycard from './img/storycard.png'
import flow from './img/flow.png'
import devteam from './img/developer-team-897688.webp'
import business from './img/business.png'
function Percent() {
  const [counterOn, setCounterOn] = useState(false);

  const imagStyle = {
    width: "48px",
    height: "46px",
  };
  const MyTypography3 = styled(Typography)({
    display: "inline",
    fontSize: 40,
    color: "#ff761c",
    fontWeight: "700",
    fontFamily: "Open Sans",
  });

  const GridBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
  });

  const MyGrid = styled(Grid)({
    "@media ((min-width: 992px) and (max-width: 1200px) )": {
      maxWidth: "0%",
    },
  });
  const MyBox = styled(Box)({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minHeight: "100px",

    "&::after": {
      position: "absolute",
      content: "'up to'",
      top: "45px",
      right: "35px",
      fontWeight: "400",
      fontSize: 16,
    },
  });

  const MyBox2 = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",

    "@media screen and (min-width: 665px) and (max-width: 992px)": {
      minWidth: "210px",
    },

    "@media screen and (min-width: 992px) and (max-width: 1200px)": {
      minWidth: "210px",
    },

    "@media screen and (min-width: 1200px)": {
      minWidth: "60%",
    },
  });

  return (
    <MyGrid
      container
      spacing={1}
      sx={{
        display: "flex",
        justifyContent: "center",
        borderRadius: "25px",
        mt: 3,
        maxWidth: "85%",
        margin: "0 auto",
      }}
    >
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={Pimg1} alt="" />
          </Box>
          <MyBox2>
            <Typography sx={{ fontSize: "20px", fontFamily: "Open Sans" }}>
              Requirement Analysis <br /> Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
            <ScrollTrigger
              sx={{ display: "none" }}
              onEnter={() => setCounterOn(true)}
              // onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <MyTypography3>
                  <CountUp start={0} end={90} duration={2} delay={0} />%
                </MyTypography3>
              )}
            </ScrollTrigger>
            {/* <MyTypography2>up to</MyTypography2> */}
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={Pimg2} alt="" />
          </Box>
          <MyBox2>
            <Typography sx={{ fontSize: "20px", fontFamily: "Open Sans" }}>
              Form Design <br /> Accurasy
            </Typography>
          </MyBox2>
          <MyBox>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <MyTypography3>
                  <CountUp start={0} end={90} duration={2} delay={0} />%
                </MyTypography3>
              )}
            </ScrollTrigger>
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={flow} alt="" />
          </Box>
          <MyBox2>
            <Typography sx={{ fontSize: "20px", fontFamily: "Open Sans" }}>
              Information Flow <br /> Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <MyTypography3>
                  <CountUp start={0} end={90} duration={2} delay={0} />%
                </MyTypography3>
              )}
            </ScrollTrigger>
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={Pimg4} alt="" />
          </Box>
          <MyBox2>
            <Typography sx={{ fontSize: "20px", fontFamily: "Open Sans" }}>
              Project Duration <br /> Estimation Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <MyTypography3>
                  <CountUp start={0} end={90} duration={2} delay={0} />%
                </MyTypography3>
              )}
            </ScrollTrigger>
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={api} alt="" />
          </Box>
          <MyBox2>
            <Typography sx={{ fontSize: "20px", fontFamily: "Open Sans" }}>
              API Documentation <br /> Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <MyTypography3>
                  <CountUp start={0} end={90} duration={2} delay={0} />%
                </MyTypography3>
              )}
            </ScrollTrigger>
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={db} alt="" />
          </Box>
          <MyBox2>
            <Typography sx={{ fontSize: "20px", fontFamily: "Open Sans" }}>
              DB Documentation <br /> Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <MyTypography3>
                  <CountUp start={0} end={90} duration={2} delay={0} />%
                </MyTypography3>
              )}
            </ScrollTrigger>
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={storycard} alt="" />
          </Box>
          <MyBox2>
            <Typography sx={{ fontSize: "20px", fontFamily: "Open Sans" }}>
              Users Story <br /> Documentation Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <MyTypography3>
                  <CountUp start={0} end={90} duration={2} delay={0} />%
                </MyTypography3>
              )}
            </ScrollTrigger>
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={Pimg7} alt="" />
          </Box>
          <MyBox2>
            <Typography sx={{ fontSize: "20px", fontFamily: "Open Sans" }}>
              Project Management  <br />Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <MyTypography3>
                  <CountUp start={0} end={90} duration={2} delay={0} />%
                </MyTypography3>
              )}
            </ScrollTrigger>
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={digital} alt="" />
          </Box>
          <MyBox2>
            <Typography sx={{ fontSize: "20px", fontFamily: "Open Sans" }}>
              Digital Services <br /> Analysis Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <MyTypography3>
                  <CountUp start={0} end={90} duration={2} delay={0} />%
                </MyTypography3>
              )}
            </ScrollTrigger>
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={issue} alt="" />
          </Box>
          <MyBox2>
            <Typography sx={{ fontSize: "20px", fontFamily: "Open Sans" }}>
              Issue Management <br /> Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <MyTypography3>
                  <CountUp start={0} end={90} duration={2} delay={0} />%
                </MyTypography3>
              )}
            </ScrollTrigger>
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={business} alt="" />
          </Box>
          <MyBox2>
            <Typography sx={{ fontSize: "20px", fontFamily: "Open Sans" }}>
              Business Communication <br /> Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <MyTypography3>
                  <CountUp start={0} end={100} duration={2} delay={0} />%
                </MyTypography3>
              )}
            </ScrollTrigger>
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={devteam} alt="" />
          </Box>
          <MyBox2>
            <Typography sx={{ fontSize: "20px", fontFamily: "Open Sans" }}>
              DevTeam Communication <br /> Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {counterOn && (
                <MyTypography3>
                  <CountUp start={0} end={100} duration={2} delay={0} />%
                </MyTypography3>
              )}
            </ScrollTrigger>
          </MyBox>
        </GridBox>
      </Grid>
    </MyGrid>
  );
}

export default Percent;
