import {
  Box,
  FormControlLabel,
  Grid,
  Skeleton,
  Switch,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import GetData from "../../api_calls/Api_Product_Detail/getData";
import { useDispatch, useSelector } from "react-redux";
import { allTrueValue } from "../../store/productsBoolean";
import { allFalseValue } from "../../store/productsBoolean";
import CircleProduct from "./CircleProduct";
import ProductCard from "./ProductCard";
import { ShowAllBox } from "./style/allProduct";
import { colorMassiv } from "../../utils/colorMassiv";
function AllProduct(props) {
  // let { allData } = props;
  const dispatch = useDispatch();
  GetData();
  const { productDetail } = useSelector((state) => state.productDetail);
  const [checked, setChecked] = useState(false);
  const [showall, setShowAll] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleChecked = (event) => {
    setOpen(!open);
    setShowAll("clicked");
  };

  useEffect(() => {
    dispatch(allFalseValue());
    if (checked) {
      dispatch(allTrueValue());
    }
  }, [checked, setOpen]);
  console.log(productDetail);
  useEffect(() => {
    dispatch(allTrueValue());
  }, []);
  return (
    <Box>
      <Box>
        <ShowAllBox>
          <Typography
            sx={{
              fontFamily: "Open Sans",
              color: "#333333",
              cursor: "pointer",
            }}
          >
            <FormControlLabel
              label="Show All"
              labelPlacement="start"
              control={<Switch onChange={handleChecked} />}
            />
          </Typography>
        </ShowAllBox>
        <Box>
          {productDetail.length !== 0 ? (
            <Box>
              {productDetail.map((index, key) => (
                <Box
                  key={key}
                  sx={{
                    marginTop: 5,
                    width: "100%",
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                >
                  <Grid container>
                    <Grid item className="allprod-nums" lg={3} md={3}>
                      <Box sx={{ display: "flex", margin: "0 3px" }}>
                        <CircleProduct
                          colorMassiv={colorMassiv}
                          number={key + 1}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={9} lg={9}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "start",
                          position: "relative",
                        }}
                      >
                        <ProductCard
                          open={open}
                          detail={index}
                          colorMassiv={colorMassiv}
                          showall={showall}
                          setOpen={setOpen}
                          number={key + 1}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              <Skeleton animation="wave" height={"100px"} width={"100%"} />
              <Skeleton animation="wave" height={"100px"} width={"100%"} />
              <Skeleton animation="wave" height={"100px"} width={"100%"} />
              <Skeleton animation="wave" height={"100px"} width={"100%"} />
              <Skeleton animation="wave" height={"100px"} width={"100%"} />
              <Skeleton animation="wave" height={"100px"} width={"100%"} />
              <Skeleton animation="wave" height={"100px"} width={"100%"} />
            </Box>
          )}
        </Box>
      </Box>
      {/* <Box>
        <Skeleton animation="wave" height={"100px"} width={"100%"} />
        <Skeleton animation="wave" height={"100px"} width={"100%"} />
        <Skeleton animation="wave" height={"100px"} width={"100%"} />
        <Skeleton animation="wave" height={"100px"} width={"100%"} />
        <Skeleton animation="wave" height={"100px"} width={"100%"} />
        <Skeleton animation="wave" height={"100px"} width={"100%"} />
        <Skeleton animation="wave" height={"100px"} width={"100%"} />
      </Box> */}
    </Box>
  );
}

export default AllProduct;
