import db from '../../assets/image/db.png'
import storycard from '../../assets/image/storycard.png'
import api from '../../assets/image/Api-Icon.520c983a55cb87c6ff42.png'
export const priceData = [
    {
        img: db,
        desc: "Everything from Previous Plans plus:",
        title: "Free",
        btn:"Try",
        color: "#DB6943",
        price: "$0.0",
        list: ["20 User Story Cards",
            "20 Database Cards",
            "20 API Cards",
            "Unlimited Digital Services",
            "Unlimited User Story Flow",
            "Unlimited Flow Diagram",
            "Unlimited Entity Diagram",
            "Unlimited Role-Map Diagram",
            "Share User Story Cards",
            "Share API Cards",
            "Share DB Cards",
        ]
    },
    {
        img: storycard,
        desc: "Everything from Previous Plans plus:",
        title: "Story Card",
        btn:"Try",
        color: "#09B5C5",
        price: "$0.39",
        month:"/month/card",
        list: [
            "Live Prototype/MVP",
            "Inputs & Descriptions",
            "Input - API Relation",
            "Input - Database Relation",
            "User Story Flow",
            "Figma Integration",
            "Google Drive Integration",
            "Draw.io Integration",
            "Jira Integration",
            " Trello Integration",
            "Integration with other Embedded Systems"],
    },
    {
        img: api,
        desc: "Everything from Previous Plans plus:",
        title: "API Card",
        btn:"Try",
        color: "#E0A841",
        price: "$0.19",
        month:"/month/card",
        list: ["API Management",
            "API Configuration",
            "API Test Case Management",
            "API Diagram",
            "User Story Card Relation"]
    },
    {
        img: db,
        desc: "Everything from Previous Plans plus:",
        title: "Database Card",
        btn:"Try",
        color: "#09626f",
        price: "$0.29",
        month:"/month/card",
        list: ["Table  Structure",
            "Description",
            "Foreign Key Relation",
            "Entity Relation",
            "Entity Diagram",
            "User Story Relation"
        ]

    },
    {
        img: db,
        desc: "Everything from Previous Plans plus:",
        title: "Enterprise ",
        btn:"Contact Sales",
        color: "#e9314c",
        price: "License Based",
        list: ["GIT Integration",
            "Automatic Database Structure Documentation",
            "Oracle Database Integration ",
            " MySql Database Integration ",
            "MsSql Database Integration",
            "PostgreSql Database Integration",
            "Automatic API Document Generation",
            "GIT Integration",
            "GitLab/GitHub Integration",
            "JAVA Codes Documentation",
            "Python Codes Documentation ",
            "C# Codes Documentation",
            "Node JS Codes Documentation",
            "Swagger Integration",
            " Jira Integration",
            "Trello Integration",]
    },
]