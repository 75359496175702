import { Box, Grid} from "@mui/material";
import React from "react";
import GetData from "../../Api/getData";
import { useSelector } from "react-redux";
import MentorCard from "./MentorCard";

function MentorMain() {
  GetData(0,3);
  const { allCourse } = useSelector((state) => state.allCourse);

  return (
    <Box sx={{ maxWidth: "800px", margin: "0 auto" }}>
      <Grid container spacing={4}>
      {allCourse?.map((index, key) => (
            <Grid key={key}  sx={{minHeight:"360px"}} item xs={12} sm={4}>
              <MentorCard index={index} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

export default MentorMain;
