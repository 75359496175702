import axios from "axios";
import { store } from "../store/index";
import BASE_URL from "./config";


export const callApi = (apiId,data, isAsync = true) => {
  const response = axios
    .post(
      `${BASE_URL}api/post/calle/${apiId}`,
      {
        kv: { ...data  },
      }
    )
    .then((res) => res.data);
  return response;
};
