import { Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const TutorialsCard = ({el,i}) => {
  return (
    <Grid key={i} item xs={12} sm={4} lg={4} >
                <Link to={`/single-tutorial/${el.id}`}>
                  <Card
                    className="tutorial-card"
                    sx={{ height: "100%", position: "relative", width: "100%" }}
                  >
                    <CardMedia
                      image={el?.videoFileUrl}
                      sx={{ height: "140px" }}
                      title="green iguana"
                    />
                    <CardContent>
                      <Typography
                        sx={{ fontSize: "16px", fontWeight: "600" }}
                        gutterBottom
                        variant="h5"
                        component="div"
                      >
                        {el?.videoTitle}
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        {el?.videoDescription.substring(0, 100)}{" "}
                        <Link to={`/single-tutorial/${el.id}`}  style={{ color: "blue" }}>...</Link>
                      </Typography>
                      <Typography
                        sx={{ fontSize: "12px", margin: "10px 0px" }}
                        variant="body2"
                        color=""
                      >
                        Duration: {el?.videoDuration}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Link
                        target="_blank"
                        to={el?.videoUrl}
                        style={{
                          color: "blue",
                          position: "absolute",
                          bottom: "3%",
                          left: "3%",
                        }}
                        size="small"
                      >
                        Watch
                      </Link>
                      {/* <Button size="small">Learn More</Button> */}
                    </CardActions>
                  </Card>
                </Link>
              </Grid>
  )
}

export default TutorialsCard